import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//scss import
import style from "./signup.module.scss";

//component import

//svg import
import { ReactComponent as Eye } from "../../resources/svg/eye.svg";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Snackbar from "../../components/snackbar/snackbar";
import Cbtn from "../../components/commonbutton/cbtn";
import {
  signupAdmin,
  selectIsLoggedIn,
  selectStatus,
} from "../../store/features/adminSlice";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import { getBidder } from "../../store/features/teamOwnerSlice";
import { getFranchise } from "../../store/features/franchiseSlice";
import { getBiddingPlayers } from "../../store/features/biddingPlayerSlice";
import { getCurator } from "../../store/features/curatorSlice";
import { Form, FormCheck } from "react-bootstrap";
import { CheckBox, CleaningServices } from "@mui/icons-material";

const Signup = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    userId: "",
    password: "",
    rePassword: "",
    showPass: [false, false],
    termsAndConditionsChecked: false,
    role: "admin",
  });
  const [error, setError] = useState(null);
  const [showError, setShowError] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  let loadingStatus = useSelector(selectStatus);
  let isLoggedInStatus = useSelector(selectIsLoggedIn);

  React.useEffect(() => {
    if (isLoggedInStatus) {
      navigate("/");
    }
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location, isLoggedInStatus]);

  const handleShowPass = (index) => {
    let temp = [...state.showPass];
    temp[index] = !temp[index];

    setState((prevState) => ({
      ...prevState,
      showPass: temp,
    }));
  };

  React.useEffect(() => {
    if (loadingStatus === "fulfilled") {
      window.location.href = "/";
    }
  }, [loadingStatus]);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    if (state.email === "") {
      setError("Invalid Email".toUpperCase());
      setShowError(true);
    } else if (state.name === "") {
      setError("Name can not be empty".toUpperCase());
      setShowError(true);
    } else if (state.password === "") {
      setError("Invalid Password".toUpperCase());
      setShowError(true);
    } else if (state.password !== state.rePassword) {
      setError("Passwords do not match".toUpperCase());
      setShowError(true);
    } else if (!state.termsAndConditionsChecked) {
      setError(
        "Please Read the Terms and Conditions and Agree to it!".toUpperCase()
      );
      setShowError(true);
    } else {
      let resData = await dispatch(
        signupAdmin({
          name: state.name,
          email: state.email,
          userId: state.userId,
          password: state.password,
          role: state.role,
          onBoarding: true,
        })
      ).unwrap();
      if (resData.statusCode === 400) {
        setError(resData.msg);
        setShowError(true);
      } else if (resData.statusCode === 304) {
        setError(resData.msg);
        setShowError(true);
      } else if (resData.statusCode === 201) {
        navigate("/");
      }
    }
  };

  return (
    <>
      {/* {loadingStatus === "Loading" && <LoadingScreen />} */}
      {/* {loadingStatus === "error" && ( */}
      <div className={style.containers}>
        <Snackbar
          show={showError}
          setShow={setShowError}
          errorMsg={error?.toUpperCase()}
          location={{ vertical: "bottom", horizontal: "center" }}
        />
        <div className={style.left} />
        <div className={style.right}>
          <div className={style.signupFormContainer}>
            <div className={style.p}>
              <p className={style.noaccount}>
                Have an account already? <a href="/login">Log in</a>
              </p>
            </div>
            <div className={style.h2}>
              <h2 className={style.signupHeading}>Let's Get Started</h2>
            </div>
            <div className={style.form}>
              <Form className={style.signupForm} onSubmit={handleSubmitClick}>
                <div className={style.inputdiv}>
                  <div className={style.inputdiv1}>
                    <label>
                      <input
                        type="name"
                        autoFocus
                        placeholder="Name"
                        id="name"
                        className={style.input1}
                        value={state.name}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                  <div className={style.inputdiv1}>
                    <label>
                      <input
                        type="email"
                        autoFocus
                        placeholder="Email"
                        id="email"
                        className={style.input1}
                        value={state.email}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                  <div className={style.inputdiv1}>
                    <label>
                      <input
                        type="name"
                        autoFocus
                        placeholder="Username"
                        id="userId"
                        className={style.input1}
                        value={state.userId}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                  <div className={style.inputdiv1}>
                    <label>
                      <input
                        type={state?.showPass[0] ? "text" : "password"}
                        autoFocus
                        placeholder="Create Password"
                        id="password"
                        className={style.input1}
                        value={state.password}
                        onChange={handleChange}
                      />
                      <span className={style.eyeSvg}>
                        <Eye
                          className={style.eye}
                          onClick={() => {
                            handleShowPass(0);
                          }}
                        />
                      </span>
                    </label>
                  </div>
                  <div className={style.inputdiv2}>
                    <label>
                      <input
                        type={state.showPass[1] ? "text" : "password"}
                        autoFocus
                        placeholder="Re-Enter Password"
                        id="rePassword"
                        className={style.input2}
                        value={state.rePassword}
                        onChange={handleChange}
                      />
                      <span className={style.eyeSvg}>
                        <Eye
                          className={style.eye}
                          onClick={() => {
                            handleShowPass(1);
                          }}
                        />
                      </span>
                    </label>
                  </div>
                  <div
                    className={style.inputdiv1 + " check-mark"}
                    style={{ marginBottom: "1rem" }}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    // }}
                  >
                    <Form.Check
                      checked={state.termsAndConditionsChecked}
                      style={{ display: "inline", marginRight: "1rem" }}
                      onClick={(e) => {
                        console.log(state.termsAndConditionsChecked);
                        setState((prevState) => ({
                          ...prevState,
                          termsAndConditionsChecked:
                            !prevState.termsAndConditionsChecked,
                        }));
                      }}
                    />
                    <span style={{ fontSize: ".8rem" }}>
                      {" "}
                      I Agree to the <a href="/tnc"> Terms & Conditions.</a>
                    </span>
                  </div>
                </div>

                <div className={style.buttonDiv}>
                  <Cbtn
                    className={style.huehuehue}
                    type={"submit"}
                    style={{ width: "100%", backgroundColor: "#0040a8" }}
                  >
                    Sign Up
                  </Cbtn>
                </div>
              </Form>
            </div>
            <div className={style.pg}>
              <p className={style.copyrightt}>
                All rights reserved &copy; THE SPECTORS
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default Signup;
