import * as React from "react";

export default function Tnc() {
  return (
    <>
      <p>Terms and Conditions for SPECAUC Software</p>
      <p>Effective Date: 20 November, 2024</p>
      <p>
        Welcome to SPECAUC! By using this software, you agree to comply with and
        be bound by the following terms and conditions. If you do not agree to
        these terms, please refrain from using the software.
      </p>
      <p>
        1. <strong>License and Usage</strong>
      </p>
      <p>
        1.1 SPECAUC grants you a non-exclusive, non-transferable, and revocable
        license to use the software for personal, non-commercial purposes only.
      </p>
      <p>
        1.2 You shall not use this software for any business or commercial
        activities.
      </p>
      <p>
        1.3 You shall not resale, redistribute, or sublicense this software in
        any form without explicit written permission from SPECAUC.
      </p>
      <p>
        2. <strong>Content Responsibility</strong>
      </p>
      <p>
        2.1 The software allows users to save and reference image URLs. SPECAUC
        does not host, store, or upload any images directly.
      </p>
      <p>
        2.2 Users are solely responsible for ensuring that the URLs they save
        within the software comply with all applicable laws, including but not
        limited to:
      </p>
      <ul>
        <li>
          Not linking to explicit material, pornography, or illegal content.
        </li>
        <li>Respecting copyrights and intellectual property rights.</li>
      </ul>
      <p>
        2.3 SPECAUC is not responsible for any misuse of image URLs saved by
        users.
      </p>
      <p>
        3. <strong>Credits and Virtual Currency</strong>
      </p>
      <p>
        3.1 SPECAUC is designed as a virtual auction platform and does not
        involve real money transactions.
      </p>
      <p>
        3.2 Any monetary value represented in the software is in the form of
        credits and holds no real-world monetary equivalence.
      </p>
      <p>
        3.3 Users acknowledge that credits cannot be redeemed, exchanged, or
        converted into real money.
      </p>
      <p>
        4. <strong>Intellectual Property</strong>
      </p>
      <p>
        4.1 All intellectual property rights in the software remain the property
        of SPECAUC.
      </p>
      <p>
        4.2 Users are prohibited from copying, decompiling, or
        reverse-engineering any part of the software.
      </p>
      <p>
        5. <strong>Disclaimer of Liability</strong>
      </p>
      <p>
        5.1 SPECAUC provides the software &quot;as-is&quot; without warranties
        of any kind, either express or implied.
      </p>
      <p>
        5.2 SPECAUC shall not be held liable for any losses, damages, or claims
        arising from the use or misuse of the software, including but not
        limited to:
      </p>
      <ul>
        <li>The saving of unauthorized or illegal URLs.</li>
        <li>
          Any activities related to booking, gambling, or wagering conducted
          through the software or using its features.
        </li>
      </ul>
      <p>
        6. <strong>Termination</strong>
      </p>
      <p>
        6.1 SPECAUC reserves the right to terminate your access to the software
        at its sole discretion, without notice.
      </p>
      <p>
        7. <strong>Governing Law and Jurisdiction</strong>
      </p>
      <p>
        7.1 These terms shall be governed by and construed in accordance with
        the laws of India.
      </p>
      <p>
        7.2 Any disputes arising out of or in connection with these terms shall
        be subject to the exclusive jurisdiction of the courts located in
        [Insert Specific City, if applicable], India.
      </p>
      <p>
        8. <strong>Amendments</strong>
      </p>
      <p>
        8.1 SPECAUC reserves the right to modify these terms at any time.
        Changes will be effective upon posting on the official platform.
      </p>
      <p>
        9. <strong>Contact Information</strong>
      </p>
      <p>For inquiries, support, or to report issues, please contact us at:</p>
      <p>
        <strong>Email:</strong> spectorsspl@gmail.com
      </p>
      <p>
        By continuing to use SPECAUC, you acknowledge that you have read,
        understood, and agreed to these terms and conditions.
      </p>
    </>
  );
}
